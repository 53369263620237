import { useContext, useEffect, useState } from "react";
import { CardsContext } from "../../State/State";

const baseSize = 2;
const maxLength = 15;

const Location = ({ className }) => {
	const { location, resetLocation } = useContext(CardsContext);
	const [fontSize, setFontSize] = useState(`${baseSize}vw`);

	useEffect(() => {
		if (location.length > maxLength) {
			setFontSize(`${(maxLength / location.length) * baseSize}vw`);
		} else {
			setFontSize(`${baseSize}vw`)
		}
	}, [location, setFontSize]);

	return (
		<div
			onClick={resetLocation}
			className={`
				card
				${className}
				flex
				flex-col
				justify-center
				items-center
				p-[2%]
				relative
			`}
		>
			<div className='font-bold flex items-center text-lg leading-none h-[50%]'>
				Location
			</div>
			<div className='h-[50%]' style={{ fontSize }}>
				{location}
			</div>
		</div>
	);
};

export default Location;

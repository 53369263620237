
export const colors = [
  "FF6C11",
  "FF3864",
  "ffd319",
  "ff901f",
  "ff2975",
  "f222ff",
  "8c1eff",
  "8a04ed",
  "570296",
  "ffe28a",
  "fffeb3",
  "ff71ce",
  "01cdfe",
  "234d20",
  "dc6900",
  "eb8c00",
  "e0301e",
  "a32020",
  "602320",
  "ffc100",
  "e5771e",
  "042134",
  "c70000",
  "eeb400",
  "e77500",
  "00420f",
  "0c200d",
  "a93332",
  "294300",
  "b50144",
  "720a1c",
  "8f2323",
  "015a2d",
  "cc6021",
  "0047ab",
  "540D6E",
  "E1A758",
  "6E88AD",
  "C55C1E",
  "F5B399"
];

import { useContext } from "react";
import { CardsContext } from "../../State/State";
import notesText from "../../assets/notes-text.png";

const Notes = ({ className }) => {
	const { notes, setNotes } = useContext(CardsContext);

	return (
		<div className={`card ${className} flex flex-col justify-center`}>
			<div
				className="w-[100%] flex justify-center"
			>
			<img alt="notes header" className="w-[30%] py-2" src={notesText}/>
			</div>
			<textarea
				className='
				h-[100%]
				rounded-bottom-inherit
				py-1 px-1 md:px-3
				bg-transparent
				text-base
				md:text-sm
			'
				placeholder='My notes...'
				value={notes}
				onChange={(e) => setNotes(e.target.value)}
			/>
		</div>
	);
};

export default Notes;

import { randomItem } from "../Helpers/Helpers";
import { aesthetics } from "./aesthetics";
import { createContext, useCallback, useReducer, useState } from "react";
import { locations } from "./locations";
import { colors } from "./colors";
import { thumbnails } from "./thumbnails";
import { editTechniques } from "./editTechniques";
import fonts from "./fonts";
import { filmTechniques } from "./filmTechniques";
import { inspirationalImages } from "./inspirationalImages";
import { scenarios } from "./scenarios";

const reset = (key, values) => {
	const value = randomItem(values);
	localStorage.setItem(key, JSON.stringify({ value }));
	return value;
};

// So you don't get the same value twice
const nonDuplicateReset = (current, key, values) => {
	let newValue = reset(key, values);
	let tries = 0;
	while (current === newValue) {
		if (tries > 20) {
			console.error(`Too many retries getting new value for key ${key}`)
			return current;
		}
		newValue = reset(key, values);
		tries++;
	};

	return newValue;
}

const get = (key, values) => {
	if (localStorage.getItem(key)) {
		return JSON.parse(localStorage.getItem(key)).value;
	} else {
		// Or set default
		return reset(key, values);
	}
};

const getNotes = () => {
	if (localStorage.getItem('notes')) {
		return JSON.parse(localStorage.getItem('notes')).note;
	} else {
		// Or set default
		return '';
	}
};

// Get random of given array or saved value
const aestheticReducer = (state) => nonDuplicateReset(state, 'aesthetics', aesthetics);
const locationReducer = (state) => nonDuplicateReset(state, 'locations', locations);
const colorReducer = (state) => nonDuplicateReset(state, 'colors', colors);
const thumbnailReducer = (state) => nonDuplicateReset(state, 'thumbnails', thumbnails);
const editTechniquesReducer = (state) => nonDuplicateReset(state, 'editTechniques', editTechniques);
const fontsReducer = (state) => nonDuplicateReset(state, 'fonts', fonts.list);
const filmTechniqueReducer = (state) => nonDuplicateReset(state, 'filmTechniques', filmTechniques);
const inspirationReducer = (state) => nonDuplicateReset(state, 'inspirationalImages', inspirationalImages);

// Get user's value, saved value, or random
const scenarioWhereIReducer = (state, action) => {
	if (action.type === 'reset') {
		return nonDuplicateReset(state, 'scenarioWhereI', scenarios.whereI);
	} else if (action.type === 'set') {
		localStorage.setItem('scenarioWhereI', JSON.stringify({ value: action.value }));
		return action.value;
	}

	return state;
};

const scenarioButReducer = (state, action) => {
	if (action.type === 'reset') {
		return nonDuplicateReset(state, 'scenarioBut', scenarios.but);
	} else if (action.type === 'set') {
		localStorage.setItem('scenarioBut', JSON.stringify({ value: action.value }));
		return action.value;
	}

	return state;
};

const init = (key) => {
	if (key === 'aesthetics') {
		return get(key, aesthetics);
	} else if (key === 'locations') {
		return get(key, locations);
	} else if (key === 'colors') {
		return get(key, colors);
	} else if (key === 'thumbnails') {
		return get(key, thumbnails);
	} else if (key === 'editTechniques') {
		return get(key, editTechniques);
	} else if (key === 'fonts') {
		return get(key, fonts);
	} else if (key === 'filmTechniques') {
		return get(key, filmTechniques);
	} else if (key === 'inspirationalImages') {
		return get(key, inspirationalImages);
	} else if (key === 'scenarioWhereI') {
		return get(key, scenarios.whereI);
	} else if (key === 'scenarioBut') {
		return get(key, scenarios.but);
	}

	return '';
};

export const CardsContext = createContext();

export const CardsProvider = ({ children }) => {
	const [aesthetic, resetAesthetic] = useReducer(aestheticReducer, '', () => init('aesthetics'));
	const [location, resetLocation] = useReducer(locationReducer, '', () => init('locations'));
	const [color, resetColor] = useReducer(colorReducer, '', () => init('colors'));
	const [thumbnail, resetThumbnail] = useReducer(thumbnailReducer, {}, () => init('thumbnails'));
	const [editTechnique, resetEditTechnique] = useReducer(editTechniquesReducer, {}, () => init('editTechniques'));
	const [font, resetFont] = useReducer(fontsReducer, {}, () => init('fonts'));
	const [filmTechnique, resetFilmTechnique] = useReducer(filmTechniqueReducer, {}, () => init('filmTechniques'));
	const [inspirationalImage, resetInspirationalImage] = useReducer(inspirationReducer, {}, () => init('inspirationalImages'));
	const [notes, setNotesInternal] = useState(getNotes());
	const [scenarioWhereI, dispatchScenarioWhereI] = useReducer(scenarioWhereIReducer, '', () => init('scenarioWhereI'));
	const [scenarioBut, dispatchScenarioBut] = useReducer(scenarioButReducer, '', () => init('scenarioBut'));

	const reset = useCallback(() => {
		resetAesthetic();
		resetLocation();
		resetColor();
		resetThumbnail();
		resetEditTechnique();
		resetFont();
		resetFilmTechnique()
		resetInspirationalImage();
		dispatchScenarioWhereI({ type: 'reset' });
		dispatchScenarioBut({ type: 'reset' });
	}, [resetAesthetic, resetLocation, resetColor, resetThumbnail, resetEditTechnique, resetFont, resetFilmTechnique, resetInspirationalImage, dispatchScenarioWhereI, dispatchScenarioBut]);

	const setNotes = useCallback((note) => {
		localStorage.setItem('notes', JSON.stringify({ note }));
		setNotesInternal(note);
	}, [setNotesInternal]);

	return (
		<CardsContext.Provider value={{
			reset,
			aesthetic, resetAesthetic,
			location, resetLocation,
			color, resetColor,
			thumbnail, resetThumbnail,
			editTechnique, resetEditTechnique,
			font, resetFont,
			filmTechnique, resetFilmTechnique,
			inspirationalImage, resetInspirationalImage,
			notes, setNotes,
			scenarioWhereI, dispatchScenarioWhereI,
			scenarioBut, dispatchScenarioBut,
		}}>
			{children}
		</CardsContext.Provider>
	);
}
import { useContext, useEffect, useState } from "react";
import { CardsContext } from "../../State/State";

const inputBase = `block w-full border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
focus:ring-2 focus:ring-inset focus:ring-indigo-600
sm:leading-6 standard-rounded
text-sm
`;

const baseSize = 2;

const Input = ({ className, value, setValue }) => {
	const [fontSize, setFontSize] = useState(`${baseSize}vw`);

	useEffect(() => {
		const maxLength = 33;
		if (value.length > maxLength) {
			setFontSize(`${(maxLength / value.length) * baseSize}vw`);
		} else {
			setFontSize(`${baseSize}vw`)
		}
	}, [value, setFontSize]);

	return (
		<input
			type="text"
			className={`
			${className} ${inputBase}
			px-2 py-0.5 md:px-4 md:py-1.5 lg:py-2
			font-normal
		`}
			style={{ fontSize }}
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
}

const row = 'w-full flex items-center gap-2 lg:gap-4';

const Scenario = ({ className }) => {
	const {
		scenarioWhereI, dispatchScenarioWhereI,
		scenarioBut, dispatchScenarioBut
	} = useContext(CardsContext);

	return (
		<div className={`${className} flex flex-col items-center justify-center
		p-1 md:p-2 lg:p-3
		gap-2 lg:gap-3
		font-bold
		`}>
			<div className={`${row}`}>
				<div className='whitespace-nowrap text-base'>
					A vlog where I
				</div>
				<Input
					value={scenarioWhereI}
					setValue={(v) => dispatchScenarioWhereI({ type: 'set', value: v })}
				/>
			</div>
			<div className={`${row}`}>
				<div className='font-bold text-base'>
					but
				</div>
				<Input
					value={scenarioBut}
					setValue={(v) => dispatchScenarioBut({ type: 'set', value: v })}
				/>
			</div>

		</div>
	)
};

export default Scenario;
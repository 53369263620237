import { useContext } from 'react';
import './Shuffle.css';
import { CardsContext } from '../../State/State';

const Shuffle = ({ className }) => {
	const { reset } = useContext(CardsContext);

	return (
		<div className={`${className} my-2 lg:my-3 flex justify-center shuffle`}>
			<button onClick={reset} className='pushable'>
				<span className='shadow standard-rounded'></span>
				<span className='edge bg-card-dark standard-rounded'></span>
				<span 
					className='
						front bg-card standard-rounded font-bold italic
						px-[2vw] py-2 lg:px-4 lg:py-2
						xs:text-2xl md:text-base
					'>
					shuffle
				</span>
			</button>
		</div>

	);
};

export default Shuffle;

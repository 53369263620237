import './App.css';
import './output.css';
import {
  Shuffle,
  FilmTechnique,
  EditTechnique,
  Thumbnail,
  Aesthetic,
  Scenario,
  Inspiration,
  Color,
  Notes,
  Location,
  Font,
} from './Cards';
import { CardsProvider } from './State/State';
import SocialLinks from './Components/SocialLinks/SocialLinks';
import { SiTiktok, SiYoutube, SiInstagram, SiPinterest } from "react-icons/si";

const Cards = ({ className }) => {
  return (
    <CardsProvider>
      <div className={`${className} flex flex-row`}>
        <div className='w-[25%] gap-[1.2%] flex flex-col align-center'>
          <Shuffle />
          <FilmTechnique className='h-[44%]' />
          <EditTechnique className='h-[44%]' />
        </div>
        <div className='w-[75%]'>
          <div className='flex flex-row h-[65%]'>
            <div className='flex w-full flex-col'>
              <div className='h-1/2 flex flex-row'>
                <Thumbnail className='w-1/2' />
                <Aesthetic className='w-1/2' />
              </div>
              <Scenario className='card h-1/2' />
            </div>
            <div className='w-1/3 gap-[1.2%] flex flex-col'>
              <Inspiration className='h-[70%]' />
              <Location className='h-[30%]' />
            </div>
          </div>

          <div className='flex flex-row h-[35%]'>
            <Font className='w-[25%]' />
            <Color className='w-[25%]' />
            <Notes className='w-[50%]' />
          </div>
        </div>
      </div>
    </CardsProvider>
  );
}


const App = () => {
  return (
    <div className='
        w-[100%] h-[100%]
          py-2
          px-[2vw]
      '
    >
      <Cards className='aspect-[5/3] lg:aspect-[5/2.5]' />
      <div className='flex justify-center gap-5 align-middle pt-3'>
        {/* Socials, tiktok, youtube, insta, pintrest */}
        <SocialLinks
          url='https://www.tiktok.com/@itsalivialee'
          Logo={() => <SiTiktok className='fill-moss' />}
        />
        <SocialLinks
          url='https://www.youtube.com/channel/UC0_JcAnPsUVPdsgWP_Bl15w'
          Logo={() => <SiYoutube className='fill-moss' />}
        />
        <SocialLinks
          url='https://www.instagram.com/alivia.leee/'
          Logo={() => <SiInstagram className='fill-moss' />}
        />
        <SocialLinks
          url='https://www.pinterest.com/alivia_leee/'
          Logo={() => <SiPinterest className='fill-moss' />}
        />
      </div>
    </div>
  );
};


export default App;

import './SocialLinks.css';

const SocialLinks = ({ className, Logo, url }) => {

	return (
		<div className={`${className} social`}>
			<button
				onClick={() => setTimeout(() => { window.open(url, '_blank') }, 500)}
				className='pushable'
			>
				<span className='shadow rounded-full'></span>
				<span className='edge bg-card-dark rounded-full'></span>
				<span className='
					front
				bg-card
					rounded-full
					w-[10vw] h-[10vw]
					text-[5vw]
					flex
					justify-center
					items-center
					'
				>
					<Logo />
				</span>
			</button>
		</div>

	);
};

export default SocialLinks;

import { useContext } from 'react';
import { CardsContext } from '../../State/State';

const Aesthetic = ({ className }) => {
	const { aesthetic, resetAesthetic } = useContext(CardsContext);

	return (
		<div className={`card ${className} flex align-middle items-center flex-col p-[2%]`}>
			<p className='italic font-bold text-2xl'
			>
				Aesthetic
			</p>
			<div
				onClick={resetAesthetic}
				className='
					flex
					justify-center
					items-center
					h-[100%]
					bold
					text-sm
					lowercase
					text-center
				'
			>
				{aesthetic}
			</div>
		</div>
	);
};

export default Aesthetic;

import { useContext } from 'react';
import { CardsContext } from '../../State/State';

const FilmTechnique = ({ className }) => {
	const { filmTechnique, resetFilmTechnique } = useContext(CardsContext);

	return (
		<div className={`card ${className} flex flex-col justify-center items-center p-[5%]`}>
			<p className='
					font-bold whitespace-nowrap
					text-base
				'
			>
				film technique
			</p>
			<div className='
				bg-moss
				standard-rounded
				w-[90%] h-[90%]
				my-[5%] md:my-[10%]
				flex
				items-center
				justify-center
				text-white
				text-sm
			'>
				<p onClick={resetFilmTechnique}>
					{filmTechnique}
				</p>
			</div>
		</div>
	);
};

export default FilmTechnique;

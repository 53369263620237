
export const aesthetics = [
	"Neon Noir",
	"Wes Anderson",
	"2014 Tumblr",
	"80s Heartthrob",
	"Acid Design",
	"Adventurecore",
	"Aestheticism",
	"After Hours",
	"Americana",
	"American Gothic",
	"Arcadecore",
	"Art Academia",
	"Blue Night",
	"Bookstore Girl",
	"Cabincore",
	"Campcore",
	"Carnivalcore",
	"Cassette Futurism",
	"Chaotic Academia",
	"Cinnamon Girl",
	"City Pop",
	"Classic Academia",
	"Cluttercore",
	"Coconut Girl",
	"Cottagecore",
	"Cryptid Academia",
	"Cryptidcore",
	"Cyberpunk",
	"Dark Academia",
	"Darkest Academia",
	"Dark Nautical",
	"Dazecore",
	"Desert",
	"Diner",
	"Disco",
	"Doodlecore",
	"Downtown Girl",
	"Dreamcore",
	"Golden Hour",
	"Grasscore",
	"Green Academia",
	"Honeycore",
	"Indie",
	"Indie Kid",
	"Jamcore",
	"Joyride",
	"Dark Paradise",
	"Light Academia",
	"Liminal Space",
	"Midwest Gothic",
	"Naturecore",
	"Nostalgiacore",
	"Rainy Day",
	"Rockstar GF",
	"Southern Gothic",
	"Stargirl",
	"Synthwave",
	"Tumblewave",
	"Vaporwave",
	"Vintage Americana",
	"Wanderlust",
	"Warmcore",
	"Western",
	"Winter Academia",
	"Spaghetti Western",
	"Coming of Age",
	"Road Films",
	"Wong Kar Wai"
];


export const filmTechniques = [
	"Extreme Long Shot",
  "Panning Shot",
  "Zoom",
  "Aerial",
  "Arc",
  "Camera Roll",
  "Central Framing",
  "Close-Up",
  "Conveyor",
  "Crash cut",
  "Cut-ins",
  "Collage",
  "Dolly Zoom",
  "First-Person",
  "Fisheye",
  "Fixed Cam",
  "Ground Level",
  "High Angle",
  "Locked-On",
  "Match motion",
  "Mixed Media",
  "Object Portal",
  "Object POV",
  "Overhead",
  "Whip Pan",
  "Pedestal",
  "Stop Motion",
  "Tilt",
  "Tracking",
  "Wipe Transition",
  "Infinite Transition",
  "Screen Transition",
  "Snorricam"
];

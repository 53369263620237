import { useContext, useEffect } from "react";
import { CardsContext } from '../../State/State';
import SocialLogo from "../../Components/SocialLogo/SocialLogo";
import { useSpringRef, useTransition, animated } from "@react-spring/web";
import './Thumbnail.css';

// From https://stackoverflow.com/questions/3646036/preloading-images-with-javascript
const preloadImage = src => new Promise((resolve, reject) => {
	const image = new Image()
	image.onload = resolve
	image.onerror = reject
	image.src = src
});

const ImageContainer = ({ thumbnail, reset, style }) => (
	<animated.img
		className='object-cover absolute w-[100%] h-[100%]'
		onClick={reset}
		src={thumbnail.img}
		alt='Social Insperational'
		style={{ ...style }}
	/>
);

// Chose icon based off source site, pinterest or youtube.
const Thumbnail = ({ className }) => {
	const { thumbnail, resetThumbnail } = useContext(CardsContext);
	const transRef = useSpringRef();
	const transitions = useTransition(thumbnail, {
		ref: transRef,
		keys: null,
		config: { tension: 280, friction: 120 },
		// Don't do anmation on initial render
		initial: { opacity: 1, transform: 'translate3d(0%,0,0)' },
		from: { opacity: 1, transform: 'translate3d(100%,0,0)' },
		enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
		leave: { opacity: .7, transform: 'translate3d(-50%,0,0)' },
	});

	// clear localstorage when depecreated key is set 
	useEffect(() => {
		if (thumbnail?.sources) resetThumbnail();
	}, [resetThumbnail, thumbnail?.sources]);

	// Preload image then start sliding animation
	useEffect(() => {
		(async () => {
			if (thumbnail) {
				await preloadImage(thumbnail.img);

			}
			transRef.start();
		})()
	}, [thumbnail, transRef]);

	if (!thumbnail) return <></>;

	return (
		<div className={`
			social-image-container
			standard-rounded
			overflow-hidden
			m-[2%]
			flex
			justify-center
			items-center
			${className}
		`}>
			<SocialLogo
				source={thumbnail.source}
				url={thumbnail.url}
			/>
			{transitions((style, thumbnail) => (
				<ImageContainer
					style={style}
					reset={resetThumbnail}
					thumbnail={thumbnail}
				/>
			))}
		</div>
	);
};

export default Thumbnail;


const source = {
	google: 'google',
	custom: 'custom',
};

const list = [
	{
		family: "Six Caps",
		source: source.google,
		size: '6vw',
	},
	{ // Put sourceName when the name in google fonts is ugly
		sourceName: "Playfair Display:ital",
		family: "Playfair Display",
		source: source.google,
		size: '2.8vw',
	},
	{
		family: "Bebas Neue",
		source: source.google,
		size: '4vw',
	},
	{
		family: "Baskervville",
		sourceName: "Baskervville:ital",
		source: source.google,
		size: '2.5vw',
	},
	{
		family: "Alien Encounters",
		source: source.custom,
		size: '2.3vw',
		fileName: 'Alien-Encounters-Italic.ttf',
	},
	{
		family: "Coca Cola ii",
		source: source.custom,
		size: '4vw',
		fileName: 'Coca-Cola-ii.ttf',
	},
	{
		family: "junegull",
		source: source.custom,
		size: '3vw',
		fileName: 'junegull-rg.otf',
	},
	{
		family: "VCR OSD Mono",
		source: source.custom,
		size: '3vw',
		fileName: 'VCR_OSD_MONO_1.001.ttf',
	},
	{
		family: "Wanted LET",
		source: source.custom,
		size: '5vw',
		fileName: 'Wanted LET Plain.ttf',
	},
	{
		family: "Brian James",
		source: source.custom,
		size: '2vw',
		fileName: 'BrianJames.ttf',
	},
	{
		family: "Good Times",
		source: source.custom,
		size: '2.2vw',
		fileName: 'good-times-rg.otf',
	},
	{
		family: "Rockabye",
		source: source.custom,
		size: '4vw',
		fileName: 'Rockabye-Regular.otf',
	}
];


// Adobe Handwriting
// Bodoni 72 Oldstyle
// Bodoni 72 Smallcaps
// Domaine Display
// Drunk
// Drunk Wide
// Georgia
// Snell Roundhand
// Soap

const fonts = {
	source,
	list,
};

export default fonts;

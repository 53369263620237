
const whereI = [
	"show my daily life",
  "show what I eat in a day",
  "have a self-care day",
  "show behind-the-scenes of video editing",
  "show my day as a video editor",
  "spend a day in a small town",
  "show small-town life",
  "spend the day alone",
  "show my morning routine",
  "show my night routine",
  "have a reset day",
  "show life in Indiana",
  "show life in the Midwest",
  "show my work from home set up",
  "give a room tour",
  "do a room makeover",
  "give a studio tour",
  "give a desk tour",
  "declutter",
  "organize my desk",
  "organize my room",
  "do spring cleaning",
  "show my clothing staples",
  "go thrifting",
  "make outfits",
  "do a thrift haul",
  "find my style",
  "do a glow-up",
  "want to dress cool",
  "do a clothing try-on",
  "create a social media strategy",
  "show my favorite filming locations",
  "want to grow my YouTube channel",
  "want to grow my TikTok account",
  "change my life",
  "celebrate my birthday",
  "make the most of my 20s",
  "get my life together",
  "want to move to a big city",
  "create a bucket list",
  "complete a bucket list",
  "go on a mini-adventure",
  "develop film at home",
  "go stargazing",
  "romanticize my life",
  "cut my hair",
  "show my monthly favorites",
  "romanticize this season",
  "go on a roadtrip",
  "share a playlist",
  "romantcizie a quiet life",
  "romanticize work",
  "romanticize early mornings",
  "romanticize this month",
  "romanticize daily life",
  "show my film equipment",
  "show my editing setup",
  "document my life",
  "climb a firetower",
  "want to complete my dream board",
  "make my dream board",
  "go on a picnic",
  "go to a bookstore",
  "go to the library",
  "go to a coffee shop"
];

const but = [
	"it's cinematic",
  "for 30 days",
  "for a week",
  "for 24 hours",
  "it's cheap",
  "it's a tier list",
  "it's a how to",
  "it's a “the art of” video",
  "it's a 100 list",
  "it's everyday",
  "I'm bored",
  "it's a short film",
  "it's like a diary entry",
  "it's a how-to for the negative",
  "it's romanticized",
  "in a small town",
  "in the midwest",
  "in the middle of nowhere",
  "it's a “mini guide”",
  "it's an “Alivia's guide”",
  "not really",
  "it's raining",
  "my room is a mess",
  "it's cold outside",
  "I vlog in public",
  "my editing software crashes",
  "my laptop crashes",
  "my screentime is high",
  "I don't have internet",
  "I'm filming myself",
  "I procrastinate",
  "I'm an introvert",
  "I have imposter syndrome",
  "I don't have motivation",
  "I don't feel creative",
  "I'm tired",
  "I'm bored",
  "it's \"the lost art of\""
];

export const scenarios = { whereI, but }

import { SiPinterest, SiTiktok, SiYoutube } from "react-icons/si";
import imageSources from "../../State/imageSources";

const SourceImage = ({ source }) => {
	if (source === imageSources.pinterest) {
		return <SiPinterest size={'2.2vw'} className='fill-white' />;
	} else if (source === imageSources.youtube) {
		return <SiYoutube size={'2.2vw'} className='fill-white' />;
	} else if (source === imageSources.tiktok) {
		return <SiTiktok size={'2.2vw'} className='fill-white' />;
	}
	
	return (<></>);
}

const SocialLogo = ({ source, url }) => {
	return (
		<a
			href={url}
			target='_blank'
			rel='noreferrer'
			className={`
				flex
				bg-moss
				justify-center
				items-center
				absolute
				bottom-[7%]
				right-[7%]
				rounded-md
				px-[3.5%]
				z-10
				py-[2%]
				opacity-80
				text-sm
				hover:cursor-pointer
			`}
		>
			<SourceImage source={source} />
		</a>
	)
}

export default SocialLogo;
import { useContext } from 'react';
import { CardsContext } from '../../State/State';

const EditTechnique = ({ className }) => {
	const { editTechnique, resetEditTechnique } = useContext(CardsContext);

	return (
		<div className={`card ${className} flex items-center flex-col p-[5%]`}>
			<p className='font-bold whitespace-nowrap text-xl'
			>
				edit technique
			</p>
			<p
				onClick={resetEditTechnique}
				className='pt-[5%] text-base'
			>
				{editTechnique}
			</p>
		</div>
	);
};

export default EditTechnique;

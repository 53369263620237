import { useContext, useEffect, useState } from "react";
import { CardsContext } from "../../State/State";
import WebFont from "webfontloader";
import './Font.css';
import { useSpring, animated } from '@react-spring/web'
import fonts from "../../State/fonts";
import './font-list.css';

const fontConfig = async (font) => {
	if (font.source === fonts.source.google) {
		return {
			google: {
				families: [font.sourceName ? font.sourceName : font.family],
			}
		}
	} else if (font.source === fonts.source.custom) {
		// const res = await fetch(``);
		// const data = await res.text();
		// console.log(data);

		return {
			custom: {
				families: [font.family],
				// urls: [`/fonts/${font.fileName}`],
			}
		}
	}

	return {};
}

const Font = ({ className }) => {
	const { font, resetFont } = useContext(CardsContext);
	const [loading, setLoading] = useState(true);
	const [springs, api] = useSpring(() => ({
		from: { opacity: 0 },
	}))

	useEffect(() => {
		setLoading(true);

		api.start({
			from: { opacity: 0 },
			to: { opacity: 1 },
			config: { duration: 500 }
		});

		if (!font) {
			setLoading(false);
		} else {
			(async () => {
				const config = await fontConfig(font);
				WebFont.load({
					events: true,
					active: () => {
						setLoading(false);
					},
					...config,
				});
			})();
		}
	}, [font, api, setLoading]);

	// Set if empty
	useEffect(() => {
		if (!font) resetFont();
	}, [font, resetFont]);

	return (
		<div className={`card flex flex-col items-center ${className} p-1`}>
			<p
				className='
					font-bold
					text-xl
					h-[20%]
				'
			>
				Font
			</p>
			<div
				onClick={resetFont}
				className='
					mt-1
					text-center
					flex
					items-center
					h-[80%]
					pb-[1rem]
				'>
				{font && !loading && (
					<animated.div style={{ ...springs }}>
						<div style={{
							fontFamily: font.family,
							fontSize: font.size,
						}}>
							{font.displayName ? font.displayName : font.family}
						</div>
					</animated.div>
				)}
			</div>
		</div>
	);
};

export default Font;

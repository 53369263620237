import { useContext } from 'react';
import { CardsContext } from '../../State/State';
import SocialLogo from '../../Components/SocialLogo/SocialLogo';

const Inspiration = ({ className }) => {
	const { inspirationalImage, resetInspirationalImage } = useContext(CardsContext);

	if (!inspirationalImage) return <></>;

	return (
		<div className={`card ${className} relative flex items-center flex-col p-[5%]`}>
			<p className='
				font-bold text-base
				'
			>
				INSPIRATION
			</p>
			<SocialLogo source={inspirationalImage.source} url={inspirationalImage.url} />
			<img
				className='
				aspect-square
				object-cover
				standard-rounded
				'
				onClick={resetInspirationalImage}
				src={inspirationalImage.img}
				alt='Insperational'
			/>
		</div>
	);
};

export default Inspiration;


export const editTechniques = [
	"Flash Cut",
  "Match Cut",
  "Quick Cuts",
  "Ratio Switch",
  "Split Screen",
  "Step-print",
  "Eyeline match",
  "Smash Cut" 
];

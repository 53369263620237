
export const locations = [
	"Train station",
    "On a train",
    "Dirt road in the county",
    "School parking lot",
    "Skate park",
    "Bleachers",
    "Phone booth",
    "Roller Dome",
    "Tall grass hill",
    "In my car",
    "Bridge",
    "Pier",
    "Lake",
    "In a boat",
    "Kitchen",
    "Flower shop",
    "Old church",
    "Field with hay bales",
    "Picnic table outside",
    "At my cluttered desk",
    "Bus stop",
    "Bar with pool table",
    "Pumpkin patch",
    "Corn maze",
    "Historic neighborhood",
    "Grocery store late at night",
    "Grocery store early in the morning",
    "Grocery store ice cream isle",
    "Grocery store cereal isle",
    "Parking lot",
    "Health food shop",
    "Outside of an empty building",
    "Under a street lamp",
    "Gas station",
    "Dead mall",
    "Elevator",
    "Bowling alley",
    "Laundromat",
    "Record store",
    "Thrift store",
    "Firetower",
    "Chair swing ride",
    "Asian restaurant at night",
    "Coffee shop with big window during the rain",
    "Empty movie theatre",
    "Crosswalk",
    "On top of a sand dune",
    "Under a tree",
    "Hiking trail",
    "Vending machine with neon lights",
    "Diner",
    "On an airplane",
    "Airport bar",
    "Bookstore",
    "Aquarium",
    "In a tree",
    "Under a bridge during the rain",
    "Field",
    "Waterfall",
    "Railroad tracks",
    "On top of a roof",
    "Roof of parking garage",
    "Flower field",
    "In a barn",
    "By a pond",
    "Arcade",
    "On stage",
    "Ice cream shop",
    "Messy desk",
    "Cliff",
    "Library",
    "Library meeting room",
    "Pizzeria",
    "Museum",
    "Mural",
    "Greenhouse",
    "Basketball court",
    "Park bench",
    "In a woods",
    "By a river",
    "Museum",
    "In an alley",
    "Downtown",
    "Bathroom",
    "At a bar",
    "Coffee shop",
    "Airport",
    "At the gym",
    "Baseball Stadium",
    "Hot dog stand",
    "Botanical garden",
    "Vineyard",
    "Theme park",
    "Movie theater",
    "Drive-in movie theater",
    "Garden",
    "Music venue",
    "Field",
    "National park",
    "Donut shop",
    "Tennis court",
    "Zoo",
    "Fast food restaurant",
    "Farmers market",
    "Hotel",
    "Farm",
    "Basketball court",
    "Bowling alley",
    "Bakery",
    "Pizzeria",
    "Food court",
    "Photo booth"
];

import { useCallback, useContext, useEffect, useState } from "react";
import { CardsContext } from "../../State/State";
import { FaRegCopy } from "react-icons/fa6";
import { FaCopy } from "react-icons/fa";
import { useSpring, animated } from '@react-spring/web'
import './Color.css';

const CopyToClipboard = ({ color }) => {
	const [clicking, setClicking] = useState(false);
	const [springs, api] = useSpring(() => ({
		from: { scale: 1 },
	}))

	useEffect(() => {
		api.start({
			to: { scale: clicking ? 1.2 : 1 },
			config: { duration: 100 }
		})
	}, [clicking, api]);

	const onClick = useCallback((e) => {
		e.preventDefault();
		setClicking(true);

		navigator.clipboard.writeText(`#${color}`);

		setTimeout(() => {
			setClicking(false);
		}, 130);
	}, [color, setClicking])

	return (
		<button
			className='absolute top-[7%] right-[7%] p-[2%] color-white'
			onClick={(e) => onClick(e)}
		>
			<animated.div
				style={{
					...springs,
				}}
			>
				<FaRegCopy
					style={{ display: clicking ? 'none' : 'block', color: 'white' }}
					size={'2.7vw'}
				/>
				<FaCopy
					style={{ display: clicking ? 'block' : 'none', color: 'white' }}
					size={'2.7vw'}
				/>
			</animated.div>

		</button>
	);
}
const Color = ({ className }) => {
	const { color, resetColor } = useContext(CardsContext);

	return (
		<div className={`${className} card flex flex-col relative`}>
			<div
				onClick={resetColor}
				style={{ background: `#${color}` }}
				id='color-box'
				className='flex rounded-top-inherit h-[80%]'
			/>
			<CopyToClipboard color={color} />
			<div className='
					flex items-center h-[20%] border-t-black border-t-2
					pl-[5%] sm:pl-[10%]
					text-xs
				'
			>
				<span className='hidden sm:inline'>hex</span>
				<span className="pl-1">#{color}</span>
			</div>
		</div>
	)
};

export default Color;
